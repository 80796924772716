import { FC, ReactNode } from "react";

export interface PrimaryButtonProps {
  onClick: () => void;
  children: ReactNode; // This allows you to pass any React node as children
}

const PrimaryButton: FC<PrimaryButtonProps> = ({ onClick, children }) => {
  return (
    <button
      onClick={onClick}
      className="bg-primary hover:bg-primaryHover text-white font-bold py-2 px-4 rounded"
    >
      {children}
    </button>
  );
};

export default PrimaryButton;
