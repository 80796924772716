const RedemptionOpportunities = () => {
  return (
    <section className="p-8">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-center text-3xl text-white font-bold mb-6">
          Redemption Opportunities
        </h2>
        <p className="text-center text-white mb-6">
          Transformative Actions for a Better You and a Better World
          <br /> Sinify encourages a journey of growth and positive change
          through diverse redemption opportunities.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="bg-backgroundSecondary rounded-lg shadow p-6 text-white">
            <h3 className="font-semibold mb-3">Volunteer Work</h3>
            <p>
              Connect with local charities and give back to the community as a
              path to redemption.
            </p>
          </div>
          <div className="bg-backgroundSecondary rounded-lg shadow p-6 text-white">
            <h3 className="font-semibold mb-3">Personal Development</h3>
            <p>
              Access resources for self-improvement and personal growth to aid
              in your journey.
            </p>
          </div>
          <div className="bg-backgroundSecondary rounded-lg shadow p-6 text-white">
            <h3 className="font-semibold mb-3">Meditation and Reflection</h3>
            <p>
              Participate in guided meditation sessions to reflect on your
              actions and find peace.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RedemptionOpportunities;
