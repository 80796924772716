import { useEffect, useState } from "react";
import Table from "../../../../components/Table/Table";
import { collection, getDocs, orderBy, query } from "@firebase/firestore";
import { db } from "../../../../firebaseConfig";
import LoadingSpinner from "../../../../components/Loading/LoadingSpinner";

interface HighscoreEntry {
  id: number;
  displayName: string;
  confessions: number;
  totalPaymentAmount: string;
}

const UserHighscore = () => {
  const [userHighscores, setUserHighscores] = useState<HighscoreEntry[]>([]);
  const [isLoading, setIsLoading] = useState(true); // New state variable for loading status

  useEffect(() => {
    const fetchUserHighscores = async () => {
      setIsLoading(true); // Start loading

      const q = query(
        collection(db, "userHighscores"),
        orderBy("confessionsCount", "desc")
      );
      const querySnapshot = await getDocs(q);
      const highscoresData = querySnapshot.docs.map((doc, index) => ({
        id: index + 1,
        displayName: doc.data().displayName,
        confessions: doc.data().confessionsCount,
        totalPaymentAmount:
          (Math.round(doc.data().totalPaymentAmount) / 100).toFixed(2) || "",
      }));
      setUserHighscores(highscoresData);
      setIsLoading(false); // End loading
    };

    fetchUserHighscores();
  }, []);

  const columns = [
    { Header: "User", accessor: "displayName" },
    { Header: "Confessions", accessor: "confessions" },
    { Header: "Total Indulgence", accessor: "totalPaymentAmount" },
  ];
  return (
    <div className="bg-backgroundSecondary rounded-lg shadow p-6">
      {isLoading && <LoadingSpinner />}

      <h3 className="text-xl text-white font-bold mb-4">
        Users with most confessions and indulgence
      </h3>
      <Table columns={columns} data={userHighscores} />
    </div>
  );
};

export default UserHighscore;
