import FeatureCard from "./FeatureCard";

const FeaturesSection = () => {
  return (
    <div className="py-1">
      <h2 className="text-3xl font-bold text-white text-center mb-10">
        Features
      </h2>
      <div className="flex flex-wrap justify-around items-start">
        <FeatureCard
          title="Digital Confession Booth"
          delay="300" // 300ms delay
          description="Confess with Confidence and Comfort - Sinify's digital confession booth offers a sanctuary where you can unburden yourself in complete anonymity. Designed for tranquility and security, our platform ensures that your confessions are a private and judgement-free experience, enabling honest self-expression."
        />
        <FeatureCard
          title="Redemption and Absolution"
          delay="600" // 300ms delay
          description="Find Peace and Purpose in Redemption - Our unique approach to absolution goes beyond traditional methods. After confessing, Sinify guides you towards meaningful actions for redemption, providing a fulfilling sense of closure and a tangible impact on both your life and the lives of others."
        />
        <FeatureCard
          title="Privacy-Centric Design"
          delay="900" // 300ms delay
          description="Your Privacy, Our Priority - We’ve crafted Sinify with an unwavering focus on privacy. From advanced encryption to strict confidentiality protocols, we go the extra mile to ensure your confessions and personal data are safeguarded with the highest standards of security."
        />
      </div>
    </div>
  );
};

export default FeaturesSection;
