import { useEffect, useState } from "react";
import Table from "../../../../components/Table/Table";
import { collection, getDocs, query, orderBy, where } from "firebase/firestore";
import { db } from "../../../../firebaseConfig"; // Adjust this import to your Firebase configuration
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../../../components/Loading/LoadingSpinner";

export interface Confession {
  id: string;
  sin: string; // Assuming this is the confession text
  date: string; // Assuming date is converted to a string
  isSharing: boolean;
  paymentAmount: string;
  uid: string;
  displayName: string;
  // Add other fields as needed
}

const RecentConfessions = () => {
  const [confessions, setConfessions] = useState<Confession[]>([]);
  const [isLoading, setIsLoading] = useState(true); // New state variable for loading status

  const navigate = useNavigate();

  useEffect(() => {
    const fetchConfessions = async () => {
      setIsLoading(true);
      try {
        const q = query(
          collection(db, "sins"),
          where("isSharing", "==", true), // Only fetch confessions that are marked for sharing
          orderBy("date", "desc")
        );
        const querySnapshot = await getDocs(q);
        const confessionData = querySnapshot.docs.map((doc) => {
          const docData = doc.data(); // Cast the data to Confession type
          return {
            // ...docData, // Spread the rest of the document data
            id: doc.id,
            sin: docData.sin || "No confession provided",
            category: docData.category || "",
            paymentAmount:
              (Math.round(doc.data().paymentAmount) / 100).toFixed(2) || "",
            date: docData.date.toDate().toLocaleDateString(),
            displayName: docData.displayName || "Anonymous",
            isSharing: docData.isSharing,
            uid: docData.uid,
          };
        });
        setConfessions(confessionData);
      } catch (error) {
        console.error("Error fetching confessions:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchConfessions();
  }, []);

  const columns = [
    { Header: "Confession", accessor: "sin" },
    { Header: "Category", accessor: "category" },
    {
      Header: "User",
      accessor: "displayName",
      Cell: (row: Confession) => {
        if (!row || !row) return null; // Check if row or row.original is undefined

        return (
          <button onClick={() => navigate(`/user/${row.uid}`)}>
            <div>{row.displayName}</div>
          </button>
        );
      },
    },
    { Header: "Indulgence", accessor: "paymentAmount" },
    { Header: "Date", accessor: "date" },
    {
      Header: "",
      accessor: "id",
      Cell: ({ id }: { id: string }) => (
        <button
          onClick={() => {
            console.log("Value", id);

            navigate(`/confessions/${id}`);
          }}
        >
          View Details
        </button>
      ),
    },
  ];

  return (
    <div className="bg-backgroundSecondary rounded-lg shadow p-6 ">
      {isLoading && <LoadingSpinner />}
      <h3 className="text-xl text-white font-bold mb-4">
        Recent Confessions from other users
      </h3>
      {/* <div className="max-h-48 overflow-y-auto"> */}
      <Table columns={columns} data={confessions} fixedHeight={true} />
      {/* </div> */}
    </div>
  );
};

export default RecentConfessions;
