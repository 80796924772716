// About.js
const About = () => {
  return (
    <div className="container mx-auto p-8">
      <h1 className="text-4xl text-white font-bold text-center">
        About Sinify
      </h1>
      {/* Content about Sinify */}
    </div>
  );
};

export default About;
