// src/components/SinifyRouter.tsx
import { Route, Routes } from "react-router-dom";
import PrivateRoute from "../Routing/PrivateRoute";
import Dashboard from "../../modules/Dashboard/Dashboard";
import About from "../About/About";
import Support from "../Support/Support";
import SignIn from "../../modules/SignIn/SignIn";
import SignUp from "../../modules/SignUp/SignUp";
import ConfessSin from "../../modules/ConfessSin/ConfessSin";
import LandingPage from "../../modules/LandingPage/LandingPage";
import UserProfile from "../../modules/UserProfile/UserProfile";
import PostConfession from "../../modules/ConfessSin/PostConfession";
import PaymentSuccess from "../../modules/Payment/PaymentSuccess";
import PaymentCancelled from "../../modules/Payment/PaymentCancelled";
import BuySinCoins from "../../modules/Payment/BuySinCoins";
import ConfessionDetail from "../../modules/ConfessSin/ConfessionDetail";
import UserDetails from "../../modules/UserDetails/UserDetails";
import ChatRoom from "../../modules/Chat/ChatRoom";
import Chats from "../../modules/Chats/Chats";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";

const SinifyRouter = () => {
  return (
    <Routes>
      <Route path="/dashboard" element={<Dashboard />} />
      <Route
        path="/profile"
        element={
          <PrivateRoute>
            <UserProfile />
          </PrivateRoute>
        }
      />
      <Route path="/about" element={<About />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />

      <Route path="/support" element={<Support />} />
      <Route path="/login" element={<SignIn />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/confess" element={<ConfessSin />} />
      <Route path="/post-confession" element={<PostConfession />} />
      <Route path="/payment-success" element={<PaymentSuccess />} />
      <Route path="/payment-cancelled" element={<PaymentCancelled />} />
      <Route
        path="/buy-sincoins"
        element={
          <PrivateRoute>
            <BuySinCoins />
          </PrivateRoute>
        }
      />
      <Route path="/confessions/:confessionId" element={<ConfessionDetail />} />
      <Route path="/user/:userId" element={<UserDetails />} />
      <Route path="/chat/:chatId" element={<ChatRoom />} />
      <Route path="/chats" element={<Chats />} />

      <Route path="/" element={<LandingPage />} />
    </Routes>
  );
};

export default SinifyRouter;
