import { useNavigate } from "react-router-dom";

const HeroSection = () => {
  const navigate = useNavigate();

  const handleGetStarted = () => {
    navigate("/signup");
  };

  return (
    <div className="text-center p-10 text-white">
      <h1 className="text-3xl font-bold">
        Welcome to Sinify - Where Your Confessions Foster a Better World
      </h1>
      <p className="text-xl my-4">
        Embark on a journey of self-reflection and redemption with Sinify. Here,
        your confessions not only pave the path to your personal absolution but
        also contribute to a greater cause. Embrace a unique opportunity to
        transform your inner reflections into outward acts of kindness.
      </p>
      <button
        onClick={handleGetStarted}
        className="bg-primary text-white p-3 rounded"
      >
        Get Started
      </button>
    </div>
  );
};

export default HeroSection;
