import "./App.css";

import Header from "./components/Header/Header";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";

import SinifyRouter from "./components/SinifyRouter/SinifyRouter";

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <div className="flex flex-col min-h-screen bg-backgroundPrimary">
          {/* bg-gradient-to-tl from-backgroundPrimary via-secondary to-backgroundSecondary"> */}
          <Header />
          <main className="flex-grow">
            <SinifyRouter />
          </main>
        </div>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
