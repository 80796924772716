import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { User, onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebaseConfig";

// Define the shape of your context
interface AuthContextType {
  currentUser: User | null;
  isAuthInitialized: boolean;
}

// Create context with a default value
const AuthContext = createContext<AuthContextType>({
  currentUser: null,
  isAuthInitialized: false,
});

// Use this hook to access the auth context
export const useAuth = () => {
  return useContext(AuthContext);
};

interface IAuthproviderProps {
  children: React.ReactNode;
}

export const AuthProvider: FC<IAuthproviderProps> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<any>();

  const [isAuthInitialized, setIsAuthInitialized] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setIsAuthInitialized(true); // Set to true once we know the user's auth status
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });

    return unsubscribe;
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser, isAuthInitialized }}>
      {children}
    </AuthContext.Provider>
  );
};
