import { Link } from "react-router-dom";

const PaymentSuccess = () => {
  return (
    <div className="container mx-auto p-8 text-center">
      <h1 className="text-3xl font-bold mb-4">Payment Successful</h1>
      <p className="text-lg mb-6">
        Thank you for your purchase! Your SinCoins have been added to your
        account.
      </p>
      <Link
        to="/dashboard"
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Go to Dashboard
      </Link>
    </div>
  );
};

export default PaymentSuccess;
