const faqs = [
  {
    question: "How does Sinify ensure my privacy?",
    answer:
      "Sinify uses state-of-the-art encryption and a strict no-logs policy to ensure your confessions remain private.",
  },
  {
    question: "What kind of redemption opportunities does Sinify offer?",
    answer:
      "Sinify offers a variety of redemption opportunities, from volunteer work to personal development programs.",
  },
  {
    question: "Is Sinify free to use?",
    answer:
      "Sinify offers a free tier as well as subscription plans with additional features and support.",
  },
];

const FAQ = () => {
  return (
    <section className="p-8">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-center text-white text-3xl font-bold mb-6">
          Frequently Asked Questions
        </h2>
        <dl className="space-y-6">
          {faqs.map((faq, index) => (
            <div key={index} className="p-4 bg-white rounded-lg shadow">
              <dt className="font-semibold">{faq.question}</dt>
              <dd className="mt-2 text-gray-600">{faq.answer}</dd>
            </div>
          ))}
        </dl>
      </div>
    </section>
  );
};

export default FAQ;
