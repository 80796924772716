// Header.js
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/sinify.png"; // Adjust the path as necessary
import { useAuth } from "../../context/AuthContext";
import { signOut } from "firebase/auth";
import { auth } from "../../firebaseConfig";
import SecondaryButton from "../Buttons/SecondaryButton";
import PrimaryButton from "../Buttons/PrimaryButton";

const Header = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const handleLoginClick = () => {
    navigate("/login"); // Redirect to the Login page
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/login");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };
  return (
    <header className="flex justify-between items-center p-4 bg-backgroundSecondary text-white">
      <div>
        <Link to="/">
          <img
            src={logo}
            alt="Sinify Logo"
            className="h-12 w-12 rounded-full"
          />
        </Link>
      </div>
      <nav>
        <Link to="/dashboard" className="p-2">
          Dashboard
        </Link>
        {currentUser && (
          <Link to="/confess" className="nav-link">
            Confess a Sin
          </Link>
        )}
        <Link to="/about" className="p-2">
          About
        </Link>
        <Link to="/support" className="p-2">
          Support
        </Link>
        {currentUser && (
          <Link to="/chats" className="p-2">
            Chats
          </Link>
        )}
        {currentUser && (
          <Link to="/profile" className="p-2">
            Profile
          </Link>
        )}
        {currentUser ? (
          <SecondaryButton onClick={handleLogout}>Log Out</SecondaryButton>
        ) : (
          <PrimaryButton onClick={handleLoginClick}>Log In</PrimaryButton>
        )}
      </nav>
    </header>
  );
};

export default Header;
