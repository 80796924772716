import React, { useState, useEffect } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { auth, db } from "../../firebaseConfig";
import { updateEmail, updatePassword } from "firebase/auth";
import PrimaryButton from "../../components/Buttons/PrimaryButton";

interface UserProfileData {
  displayName: string;
  email: string;
}

const UserProfile: React.FC = () => {
  const [userProfile, setUserProfile] = useState<UserProfileData | null>(null);
  const [newDisplayName, setNewDisplayName] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    const fetchUserProfile = async () => {
      const currentUser = auth.currentUser;
      if (currentUser) {
        const userDoc = await getDoc(doc(db, "userProfiles", currentUser.uid));
        if (userDoc.exists()) {
          console.log("Exists");
          setUserProfile(userDoc.data() as UserProfileData);
        }
      }
    };

    fetchUserProfile();
  }, []);

  const handleUpdateProfile = async () => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      try {
        if (newDisplayName) {
          await updateDoc(doc(db, "userProfiles", currentUser.uid), {
            displayName: newDisplayName,
          });
          // Update display name in Firebase Auth if needed
        }
        if (newEmail) {
          await updateEmail(currentUser, newEmail);
        }
        if (newPassword) {
          await updatePassword(currentUser, newPassword);
        }
        setSuccess("Profile updated successfully.");
      } catch (error: any) {
        setError(error.message);
      }
    }
  };

  return (
    <div className="max-w-md my-4 mx-auto p-8 bg-backgroundSecondary shadow-lg rounded">
      <h2 className="text-2xl text-white font-semibold mb-4">User Profile</h2>
      {error && <p className="text-red-500">{error}</p>}
      {success && <p className="text-green-500">{success}</p>}

      <div className="mb-4 text-white">
        <strong>Name:</strong> {userProfile?.displayName}
      </div>
      <div className="mb-4 text-white">
        <strong>Email:</strong> {userProfile?.email}
      </div>
      <div className="mb-4">
        <label
          htmlFor="newDisplayName"
          className="block text-white text-sm font-bold mb-2"
        >
          New Display Name
        </label>
        <input
          type="text"
          id="newDisplayName"
          placeholder="New Display Name"
          value={newDisplayName}
          onChange={(e) => setNewDisplayName(e.target.value)}
          className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>

      <div className="mb-4">
        <label
          htmlFor="newEmail"
          className="block text-white text-sm font-bold mb-2"
        >
          New Email Address
        </label>
        <input
          type="email"
          id="newEmail"
          placeholder="New Email Address"
          value={newEmail}
          onChange={(e) => setNewEmail(e.target.value)}
          className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>

      <div className="mb-6">
        <label
          htmlFor="newPassword"
          className="block text-white text-sm font-bold mb-2"
        >
          New Password
        </label>
        <input
          type="password"
          id="newPassword"
          placeholder="New Password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          className="shadow border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>

      <div>
        <PrimaryButton onClick={handleUpdateProfile}>
          Update Profile
        </PrimaryButton>
      </div>
    </div>
  );
};

export default UserProfile;
