import { useEffect, useState } from "react";
import Table from "../../../../components/Table/Table";
import { collection, getDocs, orderBy, query } from "@firebase/firestore";
import { db } from "../../../../firebaseConfig";
import LoadingSpinner from "../../../../components/Loading/LoadingSpinner";
interface SinCategoryCount {
  category: string;
  confessionsCount: number;
  totalPaymentAmount: string;
}

const PopularSinsHighscore = () => {
  const [popularSins, setPopularSins] = useState<SinCategoryCount[]>([]);
  const [isLoading, setIsLoading] = useState(true); // New state variable for loading status

  useEffect(() => {
    const fetchPopularSins = async () => {
      setIsLoading(true); // Start loading

      const q = query(
        collection(db, "popularSins"),
        orderBy("confessionsCount", "desc")
      );
      const querySnapshot = await getDocs(q);
      const sinsData = querySnapshot.docs.map((doc) => ({
        category: doc.id,
        confessionsCount: doc.data().confessionsCount,
        totalPaymentAmount:
          (Math.round(doc.data().totalPaymentAmount) / 100).toFixed(2) || "",
      }));
      setPopularSins(sinsData);
      setIsLoading(false); // End loading
    };

    fetchPopularSins();
  }, []);

  const columns = [
    { Header: "Sin", accessor: "category" },
    { Header: "Confessions", accessor: "confessionsCount" },
    { Header: "Total Indulgence", accessor: "totalPaymentAmount" },
  ];

  return (
    <div className="bg-backgroundSecondary rounded-lg shadow p-6">
      {isLoading && <LoadingSpinner />}

      <h3 className="text-xl text-white font-bold mb-4">Popular Sins</h3>
      <Table columns={columns} data={popularSins} />
    </div>
  );
};

export default PopularSinsHighscore;
