import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { addDoc, collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import { useAuth } from "../../context/AuthContext";
import PrimaryButton from "../../components/Buttons/PrimaryButton";

interface ConfessionData {
  id: string;
  sin: string;
  indulgence: number;
  reflection: string;
  displayName: string;
  // ...other fields
}

interface Comment {
  text: string;
  userDisplayName: string;
  createdAt: string; // Format the date as needed
}

const ConfessionDetail = () => {
  const [confession, setConfession] = useState<ConfessionData | null>(null);
  const [newComment, setNewComment] = useState("");
  const [comments, setComments] = useState<Comment[]>([]);

  const { confessionId } = useParams<{ confessionId: string }>();
  const { currentUser } = useAuth(); // Get the current user

  useEffect(() => {
    const fetchConfessionAndComments = async () => {
      if (confessionId) {
        // Fetch confession
        const docRef = doc(db, "sins", confessionId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const confessionData = docSnap.data() as ConfessionData;
          setConfession({ ...confessionData, id: docSnap.id });
        } else {
          console.log("No such document!");
        }

        // Fetch comments
        const commentsRef = collection(db, "sins", confessionId, "comments");
        const commentsSnap = await getDocs(commentsRef);
        const fetchedComments: Comment[] = commentsSnap.docs.map((doc) => ({
          text: doc.data().text,
          userDisplayName: doc.data().userDisplayName,
          createdAt: doc.data().createdAt.toDate().toLocaleDateString(),
        }));

        console.log("comments", fetchedComments);

        setComments(fetchedComments);
      }
    };

    fetchConfessionAndComments();
  }, [confessionId]);

  //   useEffect(() => {
  //     const fetchConfession = async () => {
  //       if (confessionId) {
  //         const docRef = doc(db, "sins", confessionId);
  //         const docSnap = await getDoc(docRef);

  //         if (docSnap.exists()) {
  //           const confessionData = docSnap.data() as ConfessionData;
  //           setConfession({ ...confessionData, id: docSnap.id });
  //         } else {
  //           console.log("No such document!");
  //         }
  //       }
  //     };
  //     fetchConfession();
  //   }, [confessionId]);

  const handleAddComment = async () => {
    if (!currentUser) {
      console.error("User must be logged in to comment");
      return;
    }

    if (!confessionId) {
      console.error("No confession selected");
      return;
    }

    try {
      const commentTimestamp = new Date(); // Current timestamp
      const commentData = {
        text: newComment,
        userDisplayName: currentUser.displayName || "Anonymous",
        createdAt: commentTimestamp,
      };

      // Add the new comment to Firestore
      await addDoc(
        collection(db, "sins", confessionId, "comments"),
        commentData
      );

      // Format the timestamp for display
      const formattedTimestamp = commentTimestamp.toLocaleString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });

      // Add the new comment to the local state
      setComments((prevComments) => [
        ...prevComments,
        { ...commentData, createdAt: formattedTimestamp },
      ]);

      // Reset the new comment input field
      setNewComment("");
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl text-white font-bold text-center mb-4">
        Confession Details
      </h1>
      {confession && (
        <div className="bg-white p-6 rounded shadow">
          <p>
            <strong>Confessed by:</strong> {confession.displayName}
          </p>
          <p>
            <strong>Sin:</strong> {confession.sin}
          </p>
          <p>
            <strong>Reflection:</strong> {confession.reflection}
          </p>
          <p>
            <strong>Indulgence:</strong> {confession.indulgence} SinCoins
          </p>
          {/* Example comments */}
          <div className="mt-6">
            <h3 className="text-lg font-semibold mb-2">Comments</h3>
            <div className="mt-6 bg-white p-6 rounded shadow">
              {/* existing code */}
              {/* Render comments */}
              {comments.map((comment, index) => (
                <div key={index} className="mb-2">
                  <p>
                    <strong>{comment.userDisplayName}:</strong> {comment.text}
                  </p>
                  <p className="text-sm text-gray-500">
                    {new Date(comment?.createdAt).getFullYear()}
                    {/* {comment.createdAt.toDate().toLocaleString()} */}
                  </p>
                </div>
              ))}
              {/* Add comment form */}
              {/* existing code */}
            </div>
            <textarea
              className="w-full p-2 border rounded mb-2"
              rows={3}
              placeholder="Add a comment..."
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
            ></textarea>
            <PrimaryButton onClick={handleAddComment}>
              Add Comment
            </PrimaryButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConfessionDetail;
