import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import { useAuth } from "../../context/AuthContext";

const ConfessSinButton = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const handleConfessClick = () => {
    if (currentUser) {
      navigate("/confess");
    } else {
      navigate("/signup");
    }
  };
  return (
    <PrimaryButton onClick={handleConfessClick}>Confess a Sin</PrimaryButton>
  );
};
export default ConfessSinButton;
