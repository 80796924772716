import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import LoadingSpinner from "../Loading/LoadingSpinner";

type PrivateRouteProps = {
  children: JSX.Element;
};

const PrivateRoute = ({ children }: PrivateRouteProps) => {
  const { currentUser, isAuthInitialized } = useAuth();
  const location = useLocation();

  console.log("Currentuser", currentUser);
  if (!isAuthInitialized) {
    return <LoadingSpinner />; // Show loading spinner while waiting for auth initialization
  }
  return currentUser ? (
    children
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default PrivateRoute;
