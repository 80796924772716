const PrivacyPolicy = () => {
  return (
    <div className="bg-backgroundSecondary rounded-lg shadow-lg m-4 p-4 text-white">
      <h2 className="text-lg">Privacy Policy</h2>
      <div className="mt-4">1. Information Collection and Use</div>
      <div>
        While using our Service, we may ask you to provide us with certain
        personally identifiable information that can be used to contact or
        identify you. This may include, but is not limited to: info@sinify.de
      </div>
      <div className="mt-4">2. Log Data</div>
      <div>
        We collect information that your browser sends whenever you visit our
        Service ("Log Data"). This Log Data may include information such as your
        computer's Internet Protocol ("IP") address, browser type, browser
        version, and the pages of our Service that you visit.
      </div>
      <div className="mt-4">3. Cookies</div>
      <div>
        Cookies are files with a small amount of data, which may include an
        anonymous unique identifier. We use cookies to collect information in
        order to improve our services for you.
      </div>
      <div className="mt-4">4. Data Security</div>
      <div>
        The security of your Personal Information is important to us, but
        remember that no method of transmission over the internet, or method of
        electronic storage is 100% secure.
      </div>
      <div className="mt-4">5. Service Providers</div>
      <div>
        We may employ third-party companies and individuals to facilitate our
        Service, to provide the Service on our behalf, or to assist us in
        analyzing how our Service is used.
      </div>
      <div className="mt-4">6. Compliance with Laws</div>
      <div>
        We will disclose your Personal Information where required to do so by
        law or subpoena.
      </div>
      <div className="mt-4">7. Changes to This Privacy Policy</div>
      <div>
        We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page.
      </div>
      <div className="mt-4">8. Contact Us</div>
      <div>
        If you have any questions about this Privacy Policy, please contact us.
      </div>
    </div>
  );
};

export default PrivacyPolicy;
