import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../firebaseConfig";
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  addDoc,
} from "@firebase/firestore";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import { useAuth } from "../../context/AuthContext";

interface Sin {
  id: string;
  sin: string;
  paymentAmount: number; // Adjust the type based on your data structure
  // Add other properties as needed
}

const UserDetails = () => {
  const { userId } = useParams<{ userId: string }>(); // Ensure uid is treated as a string
  const [userDetails, setUserDetails] = useState<any>(null);
  const [userSins, setUserSins] = useState<Sin[]>([]);
  const [totalPayment, setTotalPayment] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      if (!userId) return; // Ensure uid is available

      setIsLoading(true);
      try {
        // Fetch user details
        const userDocRef = doc(db, "userProfiles", userId); // Check collection name
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          setUserDetails(userDocSnap.data());
        }

        // Fetch user sins
        const sinsQuery = query(
          collection(db, "sins"),
          where("uid", "==", userId)
        );
        const sinsSnapshot = await getDocs(sinsQuery);
        let sins: Sin[] = [];
        let total = 0;

        sinsSnapshot.forEach((doc) => {
          const sinData = doc.data();
          sins.push({
            id: doc.id,
            sin: sinData.sin,
            paymentAmount: sinData.paymentAmount,
          });
          total += sinData.paymentAmount || 0;
        });

        setUserSins(sins);
        setTotalPayment(total);
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, [userId]);

  if (!userDetails) {
    return <p>No user details available.</p>;
  }

  const handleSinClick = (sinId: string) => {
    navigate(`/confessions/${sinId}`);
  };

  const startChat = async () => {
    if (!userId) return;

    const chatsRef = collection(db, "chats");
    const q = query(chatsRef, where("participants", "array-contains", userId));

    const querySnapshot = await getDocs(q);
    let existingChatId = null;

    if (currentUser) {
      const uid = currentUser.uid;
      querySnapshot.forEach((doc) => {
        const chatData = doc.data();
        if (chatData.participants.includes(uid)) {
          existingChatId = doc.id;
        }
      });

      if (existingChatId) {
        navigate(`/chat/${existingChatId}`);
      } else {
        const newChatRef = await addDoc(chatsRef, {
          participants: [currentUser.uid, userId],
        });
        navigate(`/chat/${newChatRef.id}`);
      }
    }
  };

  return (
    <div className="container text-white mx-auto p-4">
      {isLoading && <LoadingSpinner />}
      <h1 className="text-2xl text-white font-bold mb-4">User Details</h1>
      <div>
        <p>
          <strong>Name:</strong> {userDetails.displayName}
        </p>
        <p>
          <strong>Total Payment:</strong> €{(totalPayment / 100).toFixed(2)}
        </p>
        <button
          onClick={startChat}
          className="bg-primary hover:bg-primaryHover text-white font-bold py-2 px-4 rounded"
        >
          Start Chat
        </button>
      </div>
      <h2 className="text-xl font-bold mb-2">User's Sins</h2>
      <div className="max-h-96 overflow-y-auto">
        <ul>
          {userSins.map((sin) => (
            <li
              key={sin.id}
              className="p-4 bg-backgroundSecondary my-2 rounded-lg cursor-pointer hover:bg-backgroundPrimary"
              onClick={() => handleSinClick(sin.id)}
            >
              {sin.sin} - Paid: €{(sin.paymentAmount / 100).toFixed(2)}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default UserDetails;
