import { FC } from "react";

export interface ITableProps {
  columns: any[];
  data: any[];
  fixedHeight?: boolean;
}

const Table: FC<ITableProps> = ({ columns, data, fixedHeight = true }) => {
  const tableContainerClassNames = fixedHeight
    ? "max-h-64 overflow-y-auto"
    : "overflow-auto";

  return (
    <div className={tableContainerClassNames}>
      <table className="min-w-full">
        <thead>
          <tr>
            {columns.map((column) => (
              <th
                key={column.accessor}
                className="text-left font-medium text-white bg-backgroundSecondary uppercase tracking-wider py-3 px-4"
              >
                {column.Header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className={`text-white`}>
          {data.map((item, index) => (
            <tr
              key={item.id + index.toString()}
              className="border-b bg-backgroundSecondary"
            >
              {columns.map((column) => (
                <td key={column.accessor} className="py-3 px-4">
                  {column.Cell ? column.Cell(item) : item[column.accessor]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
