import { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  updateDoc,
  increment,
} from "@firebase/firestore";
import { db } from "../../firebaseConfig";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import { getCheckoutUrl } from "../Payment/stripePayment";
import { app } from "../../firebaseConfig";

const ConfessSin = () => {
  const [sin, setSin] = useState("");
  const [category, setCategory] = useState("");
  const [charity, setCharity] = useState("");
  // const [indulgence, setIndulgence] = useState(0);
  const [isSharing, setIsSharing] = useState(true);
  const [isLoading, setIsLoading] = useState(false); // State to track loading

  // const [sinCoins, setSinCoins] = useState(0); // State for user's sinCoins
  const { currentUser, isAuthInitialized } = useAuth();

  const navigate = useNavigate();

  // Call handlePaymentSuccess in useEffect or appropriate lifecycle method
  // useEffect(() => {
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const paymentSuccess = urlParams.get("paymentSuccess");
  //   if (paymentSuccess === "true") {
  //     handlePaymentSuccess();
  //   }
  // }, []);

  const handlePurchase = async (size?: string) => {
    if (!currentUser) {
      console.error("User must be authenticated");
      return;
    }
    setIsLoading(true); // Start loading

    try {
      const smallPriceId = "price_1OI5hoGnaOF20o5ayPkeAzh3";
      const standardPriceId = "price_1OHoAOGnaOF20o5aoC02JMM5";
      const freePriceId = "price_1OJYMRGnaOF20o5aGR2EcOXL";
      let checkoutUrl = "";

      if (size === "small") {
        checkoutUrl = await getCheckoutUrl(app, smallPriceId);
      } else if (size === "standard") {
        checkoutUrl = await getCheckoutUrl(app, standardPriceId);
      } else {
        checkoutUrl = await getCheckoutUrl(app, freePriceId);
      }

      window.location.href = checkoutUrl; // Redirect to Stripe checkout
    } catch (error) {
      console.error("Error during purchase:", error);
      setIsLoading(false); // Stop loading on error
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    // Logic to handle the sin submission
    const displayName = currentUser?.displayName;
    const confessionDetails = { sin, category, isSharing, displayName };
    localStorage.setItem(
      "confessionDetails",
      JSON.stringify(confessionDetails)
    );

    handlePurchase();
  };

  // Handle successful payment (could be a separate component or logic within ConfessSin)

  useEffect(() => {
    if (isAuthInitialized) {
      const urlParams = new URLSearchParams(window.location.search);
      const paymentSuccess = urlParams.get("paymentSuccess");

      const handlePaymentSuccess = async () => {
        // Ensure currentUser is defined
        if (!currentUser || !currentUser.uid) {
          console.error("User is not authenticated");
          return;
        }

        try {
          // Query the last successful payment for the current user
          const paymentsRef = collection(
            db,
            "customers",
            currentUser.uid,
            "payments"
          );
          const q = query(paymentsRef, orderBy("created", "desc"), limit(1));
          const querySnapshot = await getDocs(q);

          let lastPayment: any;
          querySnapshot.forEach((doc) => {
            // Assuming 'status' field indicates the payment status
            if (doc.data().status === "succeeded") {
              lastPayment = doc.data();
            }
          });

          if (!lastPayment) {
            console.error("No successful payment found");
            return;
          }

          // Retrieve confession details from temporary storage
          const storedData = localStorage.getItem("confessionDetails");
          if (storedData) {
            const confessionDetails = JSON.parse(storedData);
            if (confessionDetails) {
              // Write confession to the database with the payment amount
              await addDoc(collection(db, "sins"), {
                ...confessionDetails,
                uid: currentUser.uid,
                paymentAmount: lastPayment.amount,
                date: new Date(),
              });

              const displayName = currentUser.displayName;
              // Update user profile with total payment amount
              const userProfileRef = doc(db, "userProfiles", currentUser.uid);
              const userProfileSnap = await getDoc(userProfileRef);
              if (userProfileSnap.exists()) {
                await updateDoc(userProfileRef, {
                  displayName: displayName,
                  totalPaymentAmount: increment(lastPayment.amount),
                });
              } else {
                await setDoc(userProfileRef, {
                  totalPaymentAmount: lastPayment.amount,
                });
              }

              console.log("Payment: ", lastPayment.amount);

              // Clear temporary storage
              localStorage.removeItem("confessionDetails");
              navigate("/post-confession", { state: { sin, category } });
            }
          }
        } catch (error) {
          console.error("Error fetching payment data:", error);
        }
      };

      if (paymentSuccess === "true") {
        handlePaymentSuccess();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthInitialized]);

  return (
    <div className="container m-auto mt-4 p-4 flex flex-row items-center justify-center ">
      {isLoading && <LoadingSpinner />}

      <div className="w-1/2 bg-backgroundSecondary rounded-lg shadow-lg p-6">
        <h2 className="text-2xl text-white font-bold text-center mb-4">
          Confess Your Sin
        </h2>
        <form onSubmit={handleSubmit}>
          <select
            className="w-full p-2 border rounded mb-4"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            <option value="">Select Category (Optional)</option>
            <option value="anger">Anger</option>
            <option value="envy">Envy</option>
            <option value="gluttony">Gluttony</option>
            {/* Other categories */}
          </select>
          <textarea
            className="w-full p-2 border rounded mb-4"
            rows={6}
            placeholder="Describe your sin"
            value={sin}
            onChange={(e) => setSin(e.target.value)}
          ></textarea>
          <textarea
            className="w-full p-2 border rounded mb-4"
            rows={4}
            placeholder="Let us know what the indulgence should be used for. Pick a good cause!"
            value={charity}
            onChange={(e) => setCharity(e.target.value)}
          ></textarea>
          <div className="mb-4">
            <label className="text-white">
              <input
                type="checkbox"
                className="accent-primary"
                checked={isSharing}
                onChange={(e) => setIsSharing(e.target.checked)}
              />{" "}
              Publish this sin publicly
            </label>
          </div>
          <button
            type="submit"
            className="w-full bg-primary hover:bg-primaryHover text-white font-bold py-2 px-4 rounded"
          >
            Submit Confession
          </button>
        </form>
      </div>

      <div className="w-1/2 p-4">
        <p className="text-white mb-4">
          In the sanctuary of Sinify, your confessions are more than just words
          — they are a path to redemption. Here, you can unburden your soul in
          complete privacy. Share your missteps, reflect on them, and embrace
          the peace that comes with absolution. Each sin you confess is a step
          towards inner healing, bringing you closer to a sense of harmony and
          balance. Your SinCoins symbolize this journey, transforming your
          confessions into tangible milestones of personal growth. Your
          confession is private and anonymous.
        </p>
      </div>
    </div>
  );
};

export default ConfessSin;
