import { app } from "../../firebaseConfig";
import { useAuth } from "../../context/AuthContext";
import { getCheckoutUrl } from "./stripePayment";
import { useState } from "react";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import PrimaryButton from "../../components/Buttons/PrimaryButton";

const BuySinCoins = () => {
  const [isLoading, setIsLoading] = useState(false); // State to track loading
  const { currentUser } = useAuth(); // Get the current user

  const handlePurchase = async (size: string) => {
    if (!currentUser) {
      console.error("User must be authenticated");
      return;
    }
    setIsLoading(true); // Start loading

    try {
      const smallPriceId = "price_1OI5hoGnaOF20o5ayPkeAzh3";
      const standardPriceId = "price_1OHoAOGnaOF20o5aoC02JMM5";
      let checkoutUrl = "";

      if (size === "small") {
        checkoutUrl = await getCheckoutUrl(app, smallPriceId);
      } else if (size === "standard") {
        checkoutUrl = await getCheckoutUrl(app, standardPriceId);
      }

      window.location.href = checkoutUrl; // Redirect to Stripe checkout
    } catch (error) {
      console.error("Error during purchase:", error);
      setIsLoading(false); // Stop loading on error
    }
  };

  return (
    <div className="max-w-lg mx-auto p-8 bg-white shadow-lg rounded">
      {isLoading && <LoadingSpinner />}
      <h2 className="text-2xl font-semibold mb-6 text-center">Buy SinCoins</h2>

      <div className="flex flex-col space-y-4">
        <div className="flex items-center justify-between bg-gray-100 p-4 rounded">
          <div className="mr-4">
            <h3 className="text-lg font-bold">Get 100 SinCoins</h3>
            <p className="text-gray-600">For only 0,99 €</p>
          </div>
          <SecondaryButton onClick={() => handlePurchase("small")}>
            Purchase
          </SecondaryButton>
        </div>

        <div className="flex items-center justify-between bg-gray-100 p-4 rounded">
          <div className="mr-4">
            <h3 className="text-lg font-bold">Get 700 SinCoins</h3>
            <p className="text-gray-600">Best value - 4,99 €</p>
          </div>
          <PrimaryButton onClick={() => handlePurchase("standard")}>
            Purchase
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default BuySinCoins;
