const testimonials = [
  {
    id: 1,
    content:
      "Since using Sinify, I've felt a profound sense of relief and privacy that I couldn't find elsewhere.",
    author: "Anonymous",
  },
  {
    id: 2,
    content:
      "The opportunity to confess in a non-judgmental space has been liberating for me.",
    author: "Anonymous",
  },
  {
    id: 3,
    content:
      "Finding redemption through Sinify has helped me make positive changes in my life.",
    author: "Anonymous",
  },
];

const Testimonials = () => {
  return (
    <section className="p-8">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-center text-3xl text-white font-bold mb-6">
          User Testimonials
        </h2>
        <div className="flex flex-wrap justify-center items-center">
          {testimonials.map((testimonial) => (
            <div
              key={testimonial.id}
              className="max-w-md mx-4 my-4 p-4 text-white bg-backgroundSecondary rounded-lg shadow"
            >
              <p className="italic">"{testimonial.content}"</p>
              <p className="text-right mt-4">- {testimonial.author}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
