import { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from "@firebase/firestore";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import { db } from "../../firebaseConfig";

interface UserProfile {
  displayName: string;
  // Include other fields as needed
}

const Chats = () => {
  const [chats, setChats] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchChats = async () => {
      if (!currentUser) return;

      setIsLoading(true);
      try {
        const chatsRef = collection(db, "chats");
        const q = query(
          chatsRef,
          where("participants", "array-contains", currentUser.uid)
        );
        const querySnapshot = await getDocs(q);

        const chatsData = await Promise.all(
          querySnapshot.docs.map(async (chatDoc) => {
            const chatData = chatDoc.data();
            const participantIds = chatData.participants.filter(
              (p: string) => p !== currentUser.uid
            );

            const participantNames = await Promise.all(
              participantIds.map(async (id: string) => {
                const userRef = doc(db, "userProfiles", id);
                const userSnap = await getDoc(userRef);

                // Assert the type of userSnap.data() as UserProfile
                const userData = userSnap.data() as UserProfile | undefined;
                return userData?.displayName ?? "Unknown";
              })
            );

            return {
              id: chatDoc.id,
              participantNames: participantNames.join(", "),
            };
          })
        );

        setChats(chatsData);
      } catch (error) {
        console.error("Error fetching chats:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchChats();
  }, [currentUser]);

  const handleChatClick = (chatId: string) => {
    navigate(`/chat/${chatId}`);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="container text-white mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Your Chats</h1>
      {chats.length > 0 ? (
        <ul>
          {chats.map((chat) => (
            <li
              key={chat.id}
              className="p-4 bg-backgroundSecondary my-2 rounded-lg cursor-pointer hover:bg-backgroundPrimary"
              onClick={() => handleChatClick(chat.id)}
            >
              Chat with {chat.participantNames}
            </li>
          ))}
        </ul>
      ) : (
        <p>No chats available.</p>
      )}
    </div>
  );
};

export default Chats;
