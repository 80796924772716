import { useState, useEffect } from "react";
import {
  collection,
  addDoc,
  query,
  orderBy,
  onSnapshot,
  doc,
  getDoc,
} from "firebase/firestore";
import { useAuth } from "../../context/AuthContext";
import { useParams, useNavigate } from "react-router-dom";
import { db } from "../../firebaseConfig";
import PrimaryButton from "../../components/Buttons/PrimaryButton";

interface Message {
  id: string;
  text: string;
  sender: string;
  timestamp: Date;
}

const ChatRoom = () => {
  const { currentUser } = useAuth();
  const { chatId } = useParams();
  const [newMessage, setNewMessage] = useState("");
  const [messages, setMessages] = useState<Message[]>([]);
  // const [userNames, setUserNames] = useState<{ [uid: string]: string }>({});
  const navigate = useNavigate();
  const [contactName, setContactName] = useState("");

  useEffect(() => {
    const fetchChatDetails = async () => {
      if (chatId) {
        const chatRef = doc(db, "chats", chatId);
        const chatSnap = await getDoc(chatRef);

        if (chatSnap.exists()) {
          const chatData = chatSnap.data();
          const otherUserId = chatData.participants.find(
            (id: string) => id !== currentUser?.uid
          );
          const otherUserRef = doc(db, "userProfiles", otherUserId);
          const otherUserSnap = await getDoc(otherUserRef);

          if (otherUserSnap.exists()) {
            setContactName(otherUserSnap.data().displayName || "Unknown");
          }
        }
      }
    };

    const messagesRef = collection(db, `chats/${chatId}/messages`);
    const q = query(messagesRef, orderBy("timestamp"));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const messagesData = querySnapshot.docs.map((doc: any) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMessages(messagesData);
    });

    fetchChatDetails();
    return () => unsubscribe();
  }, [chatId, currentUser?.uid]);

  const sendMessage = async () => {
    if (newMessage.trim() === "") return;
    const messagesRef = collection(db, `chats/${chatId}/messages`);
    await addDoc(messagesRef, {
      text: newMessage,
      sender: currentUser?.uid,
      timestamp: new Date(),
    });
    setNewMessage("");
  };

  const formatDate = (timestamp: Date) => {
    const date = new Date(timestamp);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  return (
    <div
      style={{ maxHeight: `calc(100vh - 5rem)` }}
      className="flex text-white flex-col"
    >
      <div className="flex items-center p-3 bg-backgroundPrimary">
        <PrimaryButton onClick={() => navigate("/chats")}>Back</PrimaryButton>
        <h1 className="text-xl ml-4 font-bold">Chat with {contactName}</h1>
      </div>
      <div className="flex-grow overflow-y-auto p-3">
        {messages.map((message) => (
          <div
            key={message.id}
            className={`p-3 my-2 rounded-lg max-w-xs ${
              message.sender === currentUser?.uid
                ? "bg-backgroundSecondary ml-auto"
                : "bg-backgroundSecondary mr-auto"
            }`}
          >
            <p>{message.text}</p>
            <span className="text-xs block text-right">
              {formatDate(message.timestamp)}
            </span>
          </div>
        ))}
      </div>
      <div className="flex p-3">
        <textarea
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Type a message"
          className="flex-grow p-2 border text-backgroundPrimary border-gray-400 rounded mr-2"
          onKeyDown={handleKeyPress}
        />
        <button
          onClick={sendMessage}
          className="bg-primary text-white p-2 rounded"
        >
          Send
        </button>
      </div>
    </div>
  );
};

export default ChatRoom;
