interface FeatureCardProps {
  title: string;
  description: string;
  delay?: string;
}

const FeatureCard: React.FC<FeatureCardProps> = ({
  title,
  description,
  delay,
}) => {
  return (
    <div
      className={`max-w-sm rounded-lg overflow-hidden shadow-lg text-white bg-backgroundSecondary p-6 m-4 
        animate-fadeInUp ${delay ? "delay-{delay}" : ""}`}
    >
      <h3 className="font-bold text-xl mb-2">{title}</h3>
      <p className="text-base">{description}</p>
    </div>
  );
};

export default FeatureCard;
