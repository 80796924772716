import axios from "axios";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import PrimaryButton from "../../components/Buttons/PrimaryButton";

const PostConfession = () => {
  const [generatedText, setGeneratedText] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const sin = location.state?.sin; // Retrieve the sin passed from ConfessSin component

  useEffect(() => {
    fetchGeneratedText();
    // eslint-disable-next-line
  }, []);

  const fetchGeneratedText = async () => {
    const prompt = `Create a comforting message for someone who has just confessed this sin: "${sin}". They are seeking redemption.`;

    try {
      const response = await axios.post(
        "https://api.openai.com/v1/engines/text-davinci-003/completions",
        {
          prompt: prompt,
          max_tokens: 150,
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          },
        }
      );
      setGeneratedText(response.data.choices[0].text.trim());
    } catch (error) {
      console.error("Error fetching generated text:", error);
    }
  };

  const handleGoToDashboard = () => {
    navigate("/dashboard"); // Navigate to the dashboard
  };

  return (
    <div className="container mx-auto p-8 text-center">
      <h1 className="text-3xl text-white font-bold mb-4">
        Thank You for Your Confession
      </h1>
      <p className="text-lg mb-6 text-white">
        {generatedText || <LoadingSpinner />}
      </p>
      <PrimaryButton onClick={handleGoToDashboard}>
        Return to Dashboard
      </PrimaryButton>
    </div>
  );
};

export default PostConfession;
