import { FC, ReactNode } from "react";

export interface SecondaryButtonProps {
  onClick: () => void;
  children: ReactNode; // This allows you to pass any React node as children
}

const SecondaryButton: FC<SecondaryButtonProps> = ({ onClick, children }) => {
  return (
    <button
      onClick={onClick}
      className="bg-secondary hover:bg-secondaryHover text-white font-bold py-2 px-4 rounded"
    >
      {children}
    </button>
  );
};

export default SecondaryButton;
