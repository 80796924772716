import ConfessSinButton from "../ConfessSin/ConfessSinButton";
import PopularSinsHighscore from "./components/PopularSinsHighscore/PopularSinsHighscore";
import RecentConfessions from "./components/RecentConfessions/RecentConfessions";
import UserHighscore from "./components/UserHighscore/UserHighscore";

const Dashboard = () => {
  return (
    <div className="p-8">
      <div className="container mx-auto">
        <div className="mb-4">
          <ConfessSinButton />
        </div>
        <div className="mb-4">
          <RecentConfessions />
        </div>
        <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-8">
          <div>
            <UserHighscore />
          </div>
          <div>
            <PopularSinsHighscore />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
