import Footer from "../../components/Footer/Footer";
import FAQ from "./components/FAQ/FAQ";
import FeaturesSection from "./components/FeaturesSection/FeaturesSection";
import HeroSection from "./components/HeroSection/HeroSection";
import RedemptionOpportunities from "./components/RedemptionOpportunities/RedemptionOpportunites";
import Testimonials from "./components/Testimonials/Tesitmonials";

const LandingPage = () => {
  return (
    <div className="flex-grow ">
      <HeroSection />
      <FeaturesSection />
      <RedemptionOpportunities />
      <Testimonials />
      <FAQ />
      <Footer />
    </div>
  );
};

export default LandingPage;
