import { addDoc, collection } from "@firebase/firestore";
import React, { useState } from "react";
import { db } from "../../firebaseConfig";

interface FeedbackData {
  name: string;
  email: string;
  message: string;
}

const FeedbackForm = () => {
  const [feedback, setFeedback] = useState<FeedbackData>({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFeedback({ ...feedback, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "feedback"), feedback);
      alert("Feedback submitted successfully");
      setFeedback({ name: "", email: "", message: "" }); // Reset form after submission
    } catch (error) {
      console.error("Error submitting feedback:", error);
      alert("An error occurred while submitting feedback");
    }
  };

  return (
    <div className="max-w-md text-white mx-auto ">
      <form
        onSubmit={handleSubmit}
        className="bg-backgroundSecondary shadow-md rounded px-8 pt-6 pb-8 m-4"
      >
        <div className="mb-4">
          <label className="blocktext-sm font-bold mb-2" htmlFor="name">
            Name (Optional)
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="name"
            name="name"
            type="text"
            placeholder="Your name"
            value={feedback.name}
            onChange={handleChange}
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="email">
            Email (Optional)
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="email"
            name="email"
            type="email"
            placeholder="youremail@example.com"
            required
            value={feedback.email}
            onChange={handleChange}
          />
        </div>
        <div className="mb-6">
          <label className="block text-sm font-bold mb-2" htmlFor="message">
            Feedback
          </label>
          <textarea
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="message"
            name="message"
            placeholder="Enter your feedback here"
            required
            rows={4}
            value={feedback.message}
            onChange={handleChange}
          ></textarea>
        </div>
        <div className="flex items-center justify-between">
          <button
            className="bg-primary hover:bg-primaryHover text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Send Feedback
          </button>
        </div>
      </form>
    </div>
  );
};

export default FeedbackForm;
