import { Link } from "react-router-dom";

const PaymentCancelled = () => {
  return (
    <div className="container mx-auto p-8 text-center">
      <h1 className="text-3xl font-bold mb-4">Payment Cancelled</h1>
      <p className="text-lg mb-6">
        Your payment was not completed. If this was an error, please try again.
      </p>
      <Link
        to="/buy-sincoins"
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Try Again
      </Link>
    </div>
  );
};

export default PaymentCancelled;
